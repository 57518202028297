import React, { useEffect, useState } from 'react'
import video from '../../Images/bgVideo (1).mp4'
import servicesData from '../AboutContent/data';
import { useParams } from 'react-router';

const workSteps = [
    { id: 1, number: "01", title: "Discovery and Planning", description: "We understand your goals and outline the project scope and features." },
    { id: 2, number: "02", title: "Design and Prototyping", description: "We create wireframes and prototypes to visualize the user experience." },
    { id: 3, number: "03", title: "Development and Testing", description: "We develop the app and rigorously test it to ensure quality." },
    { id: 4, number: "04", title: "Deployment and Launch", description: "We deploy the app to the store and ensure a smooth launch." },
    { id: 5, number: "05", title: "Maintenance and Updates", description: "We provide ongoing support and updates to keep the app running smoothly." },
    { id: 6, number: "06", title: "Scaling and Optimization", description: "We optimize and scale your app to meet growing demands." }
];




function Work() {
    const { slug } = useParams();
    const service = servicesData.find(service => service.slug === slug);

    const [containerClass, setContainerClass] = useState("container-fluid");

    useEffect(() => {
        const updateContainerClass = () => {
            if (window.innerWidth >= 1300) {
                setContainerClass("container p-0");
            } else {
                setContainerClass("container-fluid p-0");
            }
        };

        updateContainerClass();
        window.addEventListener("resize", updateContainerClass);
    })

    return (
        <div className="container-fluid p-0">
            <div className="work">
                <div className="work-rev">
                    <div className="video">
                        <video src={video} autoPlay loop muted></video>
                    </div>
                    <div className="layer"></div>
                    <div className={containerClass}>
                        <div className="row m-0">
                            <div className="work-box">
                                <div className="title">Work Process</div>
                                <div className="heading">{service ? service.details[0].processHeading : 'Our Mobile App Development Process'}</div>
                                <div className="card-box">
                                    {service ? <>
                                        {service.details[0].processCard.map((step, index) => (
                                            <div key={index} className="work-card pt-3 pb-2 px-4" data-aos="zoom-in">
                                                <div className={step.id >= 4 && step.id <= 6 ? 'rev new-rev' : 'rev'}>
                                                    <div className="header d-flex">
                                                        <div className="number">{(index + 1).toString().padStart(2, '0')}</div>
                                                        <div className="title">{step.heading}</div>
                                                    </div>
                                                    <div className="para">{step.para}</div>
                                                    <div className="arrow">
                                                        <svg width="50px" height="80px" viewBox="0 0 50 80" xmlns="http://www.w3.org/2000/svg"><path class="first-path" d="M24.752,79.182c-0.397,0-0.752-0.154-1.06-0.463L2.207,57.234c-0.306-0.305-0.458-0.656-0.458-1.057s0.152-0.752,0.458-1.059l2.305-2.305c0.309-0.309,0.663-0.461,1.06-0.461c0.398,0,0.752,0.152,1.061,0.461l18.119,18.119l18.122-18.119c0.306-0.309,0.657-0.461,1.057-0.461c0.402,0,0.753,0.152,1.059,0.461l2.306,2.305c0.308,0.307,0.461,0.658,0.461,1.059s-0.153,0.752-0.461,1.057L25.813,78.719C25.504,79.027,25.15,79.182,24.752,79.182z"></path><path class="second-path" d="M24.752,58.25c-0.397,0-0.752-0.154-1.06-0.463L2.207,36.303c-0.306-0.304-0.458-0.655-0.458-1.057c0-0.4,0.152-0.752,0.458-1.058l2.305-2.305c0.309-0.308,0.663-0.461,1.06-0.461c0.398,0,0.752,0.153,1.061,0.461l18.119,18.12l18.122-18.12c0.306-0.308,0.657-0.461,1.057-0.461c0.402,0,0.753,0.153,1.059,0.461l2.306,2.305c0.308,0.306,0.461,0.657,0.461,1.058c0,0.401-0.153,0.753-0.461,1.057L25.813,57.787C25.504,58.096,25.15,58.25,24.752,58.25z"></path></svg>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </> :
                                        <>
                                            {workSteps.map((step, index) => (
                                                <div key={index} className="work-card pt-3 pb-2 px-4" data-aos="zoom-in">
                                                    <div className={step.id >= 4 && step.id <= 6 ? 'rev new-rev' : 'rev'}>
                                                        <div className="header d-flex">
                                                            <div className="number">{step.number}</div>
                                                            <div className="title">{step.title}</div>
                                                        </div>
                                                        <div className="para">{step.description}</div>
                                                        <div className="arrow">
                                                            <svg width="50px" height="80px" viewBox="0 0 50 80" xmlns="http://www.w3.org/2000/svg"><path class="first-path" d="M24.752,79.182c-0.397,0-0.752-0.154-1.06-0.463L2.207,57.234c-0.306-0.305-0.458-0.656-0.458-1.057s0.152-0.752,0.458-1.059l2.305-2.305c0.309-0.309,0.663-0.461,1.06-0.461c0.398,0,0.752,0.152,1.061,0.461l18.119,18.119l18.122-18.119c0.306-0.309,0.657-0.461,1.057-0.461c0.402,0,0.753,0.152,1.059,0.461l2.306,2.305c0.308,0.307,0.461,0.658,0.461,1.059s-0.153,0.752-0.461,1.057L25.813,78.719C25.504,79.027,25.15,79.182,24.752,79.182z"></path><path class="second-path" d="M24.752,58.25c-0.397,0-0.752-0.154-1.06-0.463L2.207,36.303c-0.306-0.304-0.458-0.655-0.458-1.057c0-0.4,0.152-0.752,0.458-1.058l2.305-2.305c0.309-0.308,0.663-0.461,1.06-0.461c0.398,0,0.752,0.153,1.061,0.461l18.119,18.12l18.122-18.12c0.306-0.308,0.657-0.461,1.057-0.461c0.402,0,0.753,0.153,1.059,0.461l2.306,2.305c0.308,0.306,0.461,0.657,0.461,1.058c0,0.401-0.153,0.753-0.461,1.057L25.813,57.787C25.504,58.096,25.15,58.25,24.752,58.25z"></path></svg>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Work;
