import React from 'react'
import Navbar from '../Component/MainComponents/Navbar'
import Footer from '../Component/MainComponents/Footer'
import SubscribeButton from '../Component/MainComponents/SubscribeButton'
import Banner from '../Component/Services/Banner'
import Cards from '../Component/Services/Cards'
import Domains from '../Component/Services/Domains'
import WorkFlow from '../Component/Services/WorkFlow'
import Data from '../Component/Services/Data'
import FAQ from '../Component/Services/FAQ'
import { useAuth } from '../Component/Context/Context'

function Services() {

 const {containerClass} = useAuth()

  return (
    <>
     <Navbar />
     <Banner/>
     <Cards/>
     <Domains/>
     <div className={containerClass}><WorkFlow/></div>
     <Data/>
     <div className={containerClass}><FAQ/></div> 
     <SubscribeButton />
     <Footer/>
    </>
  )
}

export default Services
