import React, { useEffect, useState } from 'react'

function Form() {

    const [containerClass, setContainerClass] = useState("container-fluid");

    useEffect(() => {
        const updateContainerClass = () => {
            if (window.innerWidth >= 1300) {
                setContainerClass("container p-0");
            } else {
                setContainerClass("container-fluid ps-0 pe-lg-5 pe-0");
            }
        };

        updateContainerClass();
        window.addEventListener("resize", updateContainerClass);
    })




    return (
        <>
            <div className={containerClass}>
                <div className="terms">
                    <div className="row m-0">
                        <h1>Privacy Policy</h1>

                        <p><b>Effective Date:</b> 17/02/2025</p>
                        <p>At Nasksoft, your privacy is important to us. This Privacy Policy outlines how we collect, use, protect, and manage your personal information when you visit our website at <a href="https://www.nasksoft.com" target='_blank' style={{color:"black"}}>https://www.nasksoft.com</a> (the “Site”) or engage with our services. By using our website, you agree to the terms outlined in this policy.</p>

                        <h4>1. Information We Collect</h4>
                        <p>We may collect personal and non-personal information in the following ways:</p>
                        <ul style={{ display: 'grid', gap: '10px' }}>
                            <li><b>Information You Provide Voluntarily:</b>
                                This includes your name, email address, phone number, company details, and other information submitted through contact forms, service inquiries, newsletter subscriptions, or job applications.
                            </li>
                            <li><b>Automated Information:</b>
                                We may collect data such as IP address, browser type, device information, pages visited, time spent on the site, and referral URLs through cookies and analytics tools (e.g., Google Analytics).
                            </li>
                            <li><b>Cookies and Tracking Technologies:</b>
                                Our site may use cookies to enhance user experience, track usage patterns, and improve performance. You can adjust your browser settings to manage or disable cookies
                            </li>
                        </ul>

                        <h4>2. How We Use Your Information</h4>
                        <p>The information we collect is used to:</p>
                        <ul>
                            <li>Respond to your inquiries and provide requested services</li>
                            <li>Improve website functionality and user experience</li>
                            <li>Personalize content and communications</li>
                            <li>Monitor website usage and trends</li>
                            <li>Send newsletters, updates, or marketing materials (with your consent)</li>
                            <li>Fulfill contractual obligations or legal requirements</li>
                        </ul>
                        <p>We do not sell, rent, or trade your personal information to third parties.</p>

                        <h4>3. Sharing of Information</h4>
                        <p>We may share your information with third parties in the following limited circumstances:</p>
                        <ul style={{ display: 'grid', gap: '10px' }}>
                            <li><b>Service Providers:</b>
                                We may engage trusted partners to perform tasks on our behalf, such as hosting, analytics, or communication tools. These providers are contractually obligated to protect your data.
                            </li>
                            <li><b>Legal Compliance:</b>
                                We may disclose your information if required to do so by law or if necessary to protect our rights, or comply with a judicial proceeding, court order, or legal process.
                            </li>
                            <li><b>Business Transfers:</b>
                                In the event of a merger, acquisition, or asset sale, your information may be transferred as part of the business transaction.
                            </li>
                        </ul>

                        <h4>4. Data Security</h4>
                        <p>We take reasonable technical and organizational measures to protect your personal information from unauthorized access, loss, misuse, or disclosure. However, no method of transmission over the Internet or electronic storage is completely secure. While we strive to protect your data, we cannot guarantee its absolute security.</p>

                        <h4>5. Your Rights and Choices</h4>
                        <p>You have the right to:</p>
                        <ul>
                            <li>Access the personal data we hold about you</li>
                            <li>Request correction of inaccurate or incomplete data</li>
                            <li>Request deletion of your personal information</li>
                            <li>Withdraw consent for marketing communications</li>
                            <li>Object to or restrict certain data processing activities</li>
                        </ul>
                        <p>To exercise your rights, please contact us using the details provided below.</p>

                        <h4>6. Third-Party Links</h4>
                        <p>Our website may contain links to third-party websites. This Privacy Policy does not apply to those sites, and we encourage you to review the privacy policies of any third-party sites you visit. Nasksoft is not responsible for the content or privacy practices of external websites.</p>

                        <h4>7. International Data Transfers</h4>
                        <p>If you are accessing our website from outside Pakistan, please be aware that your information may be transferred to, stored, and processed in Pakistan or other countries where our servers or service providers are located. By using our website, you consent to this transfer in accordance with applicable laws</p>

                        <h4>8. Children's Privacy</h4>
                        <p>Our website and services are not intended for children under the age of 13. We do not knowingly collect or solicit personal information from children. If you believe a child has provided us with personal information, please contact us immediately so we can take appropriate action.</p>

                        <h4>9. Changes to This Policy</h4>
                        <p>We may update this Privacy Policy from time to time to reflect changes in our practices, technologies, legal requirements, or for other operational reasons. We encourage you to review this page periodically. Continued use of our website after updates constitutes acceptance of the revised policy.</p>

                        <h4>10. Contact Us</h4>
                        <p className='mb-4'>If you have any questions, concerns, or requests regarding this Privacy Policy or your personal data, please contact us at:</p>
                        <p><b>Nasksoft</b></p>
                        <p><b>Email:</b> hello@nasksoft.com</p>
                        <p><b>Website:</b><a href="https://www.nasksoft.com" target='_blank' style={{ textDecoration: 'none' }}> https://www.nasksoft.com</a></p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Form
