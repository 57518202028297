import React from 'react'
import img1 from '../../Images/marketing-strg-mckp.webp'
function Important() {
    return (
        <>
            <div className='container-fluid p-0'>
                <div className='row m-0'>
                    <div className='col-12 p-0'>
                        <div className='background-marketing'>
                            <div className='row align-items-center justify-content-center'>
                                <div className='col-sm-6 col-12 pb-sm-0 pb-4'>
                                    <div className='heading-side' data-aos="fade-right">
                                        <h2>Proficient Technologist at the Core
                                        </h2>
                                    </div>
                                </div>
                                <div className='col-sm-6 col-8 d-flex justify-content-lg-end justify-content-center p-lg-5 p-md-4 p-3'>
                                    <div className='animation-img' data-aos="fade-left">
                                        <img src={img1} alt='.../' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Important
