import React from 'react';
import video from '../../Images/stock-footage-abstract-digital-black-particle-dots-wave-on-lights-background-abstract-wave-technology-on-white.webm';
import img from '../../Images/img-1.webp'

function Mission() {
    return (
        <>
            <div className="container-fluid p-0">
                <div className="mission">
                    <video className='mission_video' src={video} autoPlay loop muted ></video>
                    <div className="row m-0">
                        <div className="col-lg-6 col-12 d-flex justify-content-center align-items-center p-0">
                            <div className="mission-img d-lg-inline d-none" data-aos="fade-right">
                                <img src={img} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-12 mt-lg-0 mt-5 pt-lg-0 pt-4 px-0 right-box-col">
                            <div className="right-box" data-aos="fade-left">
                                <div className="title">Our Mission & Vision</div>
                                <div className="heading">We Strive for Seamless Innovation And Precision-Driven Solutions</div>
                                <div className="para">A customer-centric approach and unwavering commitment to excellence, empowering business through high-quality digital solutions. </div>
                                <div className="point-box">
                                    <div className="point">
                                        <svg width="20" height="20" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.75" y="1.125" width="31.0833" height="31.0833" rx="1.5" fill="white" stroke="#0958B6"></rect><path d="M13.9796 19.9892L10.1571 16.1667L8.85547 17.4592L13.9796 22.5834L24.9796 11.5834L23.6871 10.2909L13.9796 19.9892Z" fill="url(#paint0_linear_715_4865)"></path><defs><linearGradient id="paint0_linear_715_4865" x1="16.9176" y1="10.2909" x2="16.9176" y2="22.5834" gradientUnits="userSpaceOnUse"><stop stop-color="#0958B5"></stop><stop offset="1" stop-color="#04264F"></stop></linearGradient></defs></svg>
                                        Scalable Solutions
                                    </div>
                                    <div className="point">
                                        <svg width="20" height="20" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.75" y="1.125" width="31.0833" height="31.0833" rx="1.5" fill="white" stroke="#0958B6"></rect><path d="M13.9796 19.9892L10.1571 16.1667L8.85547 17.4592L13.9796 22.5834L24.9796 11.5834L23.6871 10.2909L13.9796 19.9892Z" fill="url(#paint0_linear_715_4865)"></path><defs><linearGradient id="paint0_linear_715_4865" x1="16.9176" y1="10.2909" x2="16.9176" y2="22.5834" gradientUnits="userSpaceOnUse"><stop stop-color="#0958B5"></stop><stop offset="1" stop-color="#04264F"></stop></linearGradient></defs></svg>
                                        Process Optimization
                                    </div>
                                    <div className="point">
                                        <svg width="20" height="20" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.75" y="1.125" width="31.0833" height="31.0833" rx="1.5" fill="white" stroke="#0958B6"></rect><path d="M13.9796 19.9892L10.1571 16.1667L8.85547 17.4592L13.9796 22.5834L24.9796 11.5834L23.6871 10.2909L13.9796 19.9892Z" fill="url(#paint0_linear_715_4865)"></path><defs><linearGradient id="paint0_linear_715_4865" x1="16.9176" y1="10.2909" x2="16.9176" y2="22.5834" gradientUnits="userSpaceOnUse"><stop stop-color="#0958B5"></stop><stop offset="1" stop-color="#04264F"></stop></linearGradient></defs></svg>
                                        Sustainable Growth
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Mission;
