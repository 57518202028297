import React from 'react'
import video from '../../Images/colorwave.mp4'
import Button from '../MainComponents/Button'

function Future() {
  return (
    <>
     <div className="container-fluid p-0">
        <div className="future">
            <div className="video">
                <video src={video} autoPlay loop muted ></video>
            </div>
           <div className="d-flex justify-content-center w-100">
           <div className="row m-0 p-sm-4 p-3" data-aos="fade-up">
                <div className="col-lg-7 col-12 p-0">
                    <div className="title">
                    Build Scalable Solutions for Sustainable Growth
                    </div>
                </div>
                <div className="col-lg-5 col-12 p-0">
                <Button />
                </div>
            </div>
           </div>
        </div>
        </div> 
    </>
  )
}

export default Future
