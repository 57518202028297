import React from 'react'
import img from '../../Images/painting-icon.webp'
import square from '../../Images/bg-square.webp'
import { Link } from 'react-router';

function Services() {

    const createSlug = (text) => text.replace(/\s+/g, '-');

    return (
        <>
            <div className="home_services">
                <div className="container-lg container-fluid p-0">
                    <div className="row m-0">
                        <div className="title">Services</div>
                        <div className="heading">Comprehensive Services To Grow Your Business</div>
                        <div className="col-12 mt-5 pt-sm-4 px-0">
                            <div className="row justify-content-between m-0 services-row" style={{ padding: "0 60px" }}>
                                <div className="col-md-4 col-sm-5 col-12 d-flex justify-content-sm-start justify-content-center p-0">
                                    <Link style={{ textDecoration: 'none' }} to={`/services/${createSlug('Web Development')}`}>
                                    <div className="service-card w-100" data-aos="fade-up-right" style={{ background: "linear-gradient(270deg, #083ca7 0%, #862ff1 100%)", }}>
                                        <div className="services-card-rev">
                                            <div className="layer"></div>
                                            <div className="sqaure">
                                                <img src={square} alt="" />
                                            </div>
                                            <div className="px-sm-4 px-3 pt-4 text-box">
                                                <div className="paint-img">
                                                    <img src={img} alt="" />
                                                </div>
                                                <div className="title">Development Services</div>
                                                <div className="text">We develop high-converting websites with top UI/UX practices to maximize traffic engagement.</div>
                                            </div>
                                            <div className="read-more">Read More</div>
                                        </div>
                                    </div>
                                    </Link>
                                </div>
                                <div className="col-md-4 col-sm-5 col-12 d-flex justify-content-sm-end justify-content-center p-0 mt-sm-0 mt-5">
                                    <Link style={{ textDecoration: 'none' }} to={`/services/${createSlug('Graphic Design')}`}>  
                                     <div className="service-card w-100" data-aos="fade-up-left" style={{ background: "linear-gradient(90deg, #297679 0%, #3FA6C1 100%)", }}>
                                        <div className="services-card-rev">
                                            <div className="layer"></div>
                                            <div className="sqaure">
                                                <img src={square} alt="" />
                                            </div>
                                            <div className="px-sm-4 px-3 pt-4 text-box">
                                                <div className="paint-img">
                                                    <img src={img} alt="" />
                                                </div>
                                                <div className="title">Digital Design Services</div>
                                                <div className="text">Our designs embody your vision, creating unique, impactful visuals that distinctively represent your brand.</div>
                                            </div>
                                            <div className="read-more">Read More</div>
                                        </div>
                                    </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 p-0">
                            <div className="row justify-content-between m-0" style={{ padding: "60px 10px 0" }}>
                                <div className="col-md-4 col-sm-5 col-12 d-flex justify-content-sm-start justify-content-center p-0">
                                    <Link style={{ textDecoration: 'none' }} to={`/services/${createSlug('Digital Marketing')}`}>  
                                     <div className="service-card w-100" data-aos="fade-up-right" style={{ background: "linear-gradient(90deg, #FCB731 0%, #F67526 100%)", }}>
                                        <div className="services-card-rev">
                                            <div className="layer"></div>
                                            <div className="sqaure">
                                                <img src={square} alt="" />
                                            </div>
                                            <div className="px-sm-4 px-3 pt-4 text-box">
                                                <div className="paint-img">
                                                    <img src={img} alt="" />
                                                </div>
                                                <div className="title">SEO & Digital Marketing</div>
                                                <div className="text">As the best digital advertising agencies should, we bring you flocks of inbound prospects.</div>
                                            </div>
                                            <div className="read-more">Read More</div>
                                        </div>
                                    </div>
                                    </Link>
                                </div>
                                <div className="col-md-4 col-sm-5 col-12 d-flex justify-content-sm-end justify-content-center p-0 mt-sm-0 mt-5">
                                    <Link style={{ textDecoration: 'none' }} to={`/services/${createSlug('Ecommerce Solutions')}`}> 
                                      <div className="service-card w-100 " data-aos="fade-up-left" style={{ background: "linear-gradient(90deg, #FE6F7B 0%, #FA5B9D 100%)", }}>
                                        <div className="services-card-rev">
                                            <div className="layer"></div>
                                            <div className="sqaure">
                                                <img src={square} alt="" />
                                            </div>
                                            <div className="px-sm-4 px-3 pt-4 text-box">
                                                <div className="paint-img">
                                                    <img src={img} alt="" />
                                                </div>
                                                <div className="title">E-commerce & Marketplace Solutions</div>
                                                <div className="text">Comprehensive e-commerce development, including tailored solutions for online stores, Amazon, and eBay.</div>
                                            </div>
                                            <div className="read-more">Read More</div>
                                        </div>
                                    </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Services
