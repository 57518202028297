import React, { useEffect, useState } from 'react'
import Navbar from '../Component/MainComponents/Navbar'
import Waves from '../Component/MainComponents/Waves'
import Banner from '../Component/AboutUs/Banner'
import Second from '../Component/AboutUs/Second'
import Card from '../Component/AboutUs/Card'
import Standered from '../Component/AboutUs/Standered'
import Searching from '../Component/AboutUs/Searching'
import Important from '../Component/AboutUs/Important'
import Increase from '../Component/AboutUs/Increase'
import Coustomer from '../Component/AboutUs/Coustomer'
import Portfolio from '../Component/AboutUs/Portfolio'
import '../About.css'
import Footer from '../Component/MainComponents/Footer'
import SubscribeButton from '../Component/MainComponents/SubscribeButton'

function Aboutus() {
  const [containerClass, setContainerClass] = useState("container-fluid");

  useEffect(() => {
    const updateContainerClass = () => {
      if (window.innerWidth >= 1300) {
        setContainerClass("container"); // Large screens and above
      } else {
        setContainerClass("container-fluid px-sm-3 px-0"); // Small screens
      }
    };

    updateContainerClass(); // Set class on mount
    window.addEventListener("resize", updateContainerClass);
  })
  return (
    <>
      <Navbar />
      <Banner />
      <Waves />
      <div className={containerClass}>
        <Second />
        <Card />
        <Standered />
        <Searching />
      </div>
      <Important />
      <div className={containerClass}>
        <Increase />
      </div>
      <Coustomer />
      <div className={containerClass}>
        <Portfolio />
      </div>
      <SubscribeButton />
      <Footer />
    </>
  )
}

export default Aboutus
