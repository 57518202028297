import React from 'react'
import Navbar from '../Component/MainComponents/Navbar'
import Banner from '../Component/HomePage/Banner'
import Footer from '../Component/MainComponents/Footer'
import Mission from '../Component/HomePage/Mission'
import Cards from '../Component/HomePage/Cards'
import Technology from '../Component/HomePage/Technology'
import Services from '../Component/HomePage/Services'
import TechMessages from '../Component/HomePage/TechMessages'
import SubscribeButton from '../Component/MainComponents/SubscribeButton'
import Statisfaction from '../Component/HomePage/Statisfaction'
import Future from '../Component/HomePage/Future'
import { useAuth } from '../Component/Context/Context'

function Homepage() {

 const {containerClass} = useAuth()

  return (
    <>
     <Navbar/> 
     <Banner/>
    <div className={containerClass}>
    <Mission/>
    <Cards/>
    </div>
     <Technology/>
     <Services/>
     <TechMessages/>
     <div className={containerClass}>
     <Statisfaction/>
     </div>
     <Future/>
     <SubscribeButton/>
     <Footer />
     </>
  )
}

export default Homepage
