import React, { useEffect, useState } from 'react'
import Button from '../MainComponents/Button'
import servicesData from '../AboutContent/data';
import { useParams } from 'react-router';

function Data() {

  const { slug } = useParams();
  const service = servicesData.find(service => service.slug === slug);
  const [containerClass, setContainerClass] = useState("container-fluid");

  useEffect(() => {
    const updateContainerClass = () => {
      if (window.innerWidth >= 1300) {
        setContainerClass("container p-0");
      } else {
        setContainerClass("container-fluid p-0");
      }
    };

    updateContainerClass();
    window.addEventListener("resize", updateContainerClass);
  })

  return (
    <>
      <div className="container-fluid p-0">
        <div className="data-service">
          <div className="video"></div>
          <div className="layer"></div>
          <div className={containerClass}>
            <div className="row m-0 p-sm-4 p-3" data-aos="fade-up">
              <div className="col-lg-7 col-12 p-0">
                <div className="title">
                  {service ? service.details[0].SecondHeading : 'Seeking for smooth data integration and enhanced business visibility?'}
                </div>
                <div className="para">{service ? service.details[0].secondPara : 'We bring the technical and functional expertise to drive your success.'}
                </div>
              </div>
              <div className="col-lg-5 col-12 p-0 d-flex align-items-center">
                <Button data-aos="zoom-in" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Data
