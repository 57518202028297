import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import img from "../../Images/b-img.webp";

function Cards() {
    const data = [
        { id: 1, title: "Cloud Computing", description: 'Optimize operations with scalable and secure cloud solutions tailored for business growth.' },
        { id: 2, title: "Cybersecurity", description: 'Protect your digital assets with advanced security solutions and risk management strategies.' },
        { id: 3, title: "IT Infrastructure", description: 'Enhance efficiency with robust IT architecture, network solutions, and seamless system integration.' },
        { id: 4, title: "Cloud Computing", description: 'Optimize operations with scalable and secure cloud solutions tailored for business growth.' },
        { id: 5, title: "Cybersecurity", description: 'Protect your digital assets with advanced security solutions and risk management strategies.' },
        { id: 6, title: "IT Infrastructure", description: 'Enhance efficiency with robust IT architecture, network solutions, and seamless system integration.' },
        { id: 7, title: "Cloud Computing", description: 'Optimize operations with scalable and secure cloud solutions tailored for business growth.' },
        { id: 8, title: "Cybersecurity", description: 'Protect your digital assets with advanced security solutions and risk management strategies.' },
        { id: 9, title: "IT Infrastructure", description: 'Enhance efficiency with robust IT architecture, network solutions, and seamless system integration.' },
    ];

    return (
        <div className="container-fluid p-0">
            <div className="carousel-cards pt-sm-4 pb-sm-5 pb-0 mb-sm-4 mb-3" data-aos="fade-up">
                <Swiper
                    slidesPerView={5}
                    spaceBetween={30}
                    loop={true}
                    pagination={{ clickable: true }}
                    modules={[Pagination]}
                    breakpoints={{
                        568: { slidesPerView: 1 },
                        768: { slidesPerView: 2 },
                        1024: { slidesPerView: 3 },
                    }}
                >
                    {data.map((item) => (
                        <SwiperSlide key={item.id} style={{ paddingBottom: '70px' }}>
                            <div className="business-card mx-2">
                                <div className="img" data-aos="fade-right">
                                    <img src={img} alt="Business" />
                                </div>
                                <div className="box">
                                    <div className="title">{item.title}</div>
                                    <div className="para">{item.description}</div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
}

export default Cards;
