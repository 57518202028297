import { createContext, useContext, useEffect, useState } from "react";

export const AuthData = createContext();
export const AuthProvider = ({ children }) => {
   const [name , setName] = useState('')
   const [containerClass, setContainerClass] = useState("container-fluid");
          
   useEffect(() => {
     const updateContainerClass = () => {
       if (window.innerWidth >= 1300) {
         setContainerClass("container p-0");
       } else {
         setContainerClass("container-fluid p-0");
       }
     };
 
     updateContainerClass();
     window.addEventListener("resize", updateContainerClass);
   })
    return (
        <AuthData.Provider value={{
            name,
            setName,
            containerClass
        }}>
            {children}
        </AuthData.Provider>
    )
 }

export const useAuth = () => useContext(AuthData)
