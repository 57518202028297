import React from 'react'
import Button from '../MainComponents/Button'
import circle from '../../Images/aboutimag.webp'

function Process() {
    return (
        <>
            <div className="container-fluid p-0">
                <div className="process">
                    <div className="row m-0 justify-content-lg-start justify-content-end">
                        <div className="col-lg-6 col-12 p-0" data-aos="fade-right">
                            <div className="title">About Us</div>
                            <div className="heading">Proficient Technologist in Innovative Solutions that Drive Growth</div>
                            <div className="para">As the leading software development company, Nasksoft excels in delivering custom-built software solutions across. We specialize in developing customized solutions to meet the client's distinctive requirements. From development, and branding to driving sales growth, our solutions are designed to help businesses scale efficiently.
                            </div>
                            <div className="point-box">
                                <div className="point">Software Solutions</div>
                                <div className="point">Business Growth</div>
                            </div>
                            <Button />
                            <div className="d-lg-none d-inline">
                            <div className='big-img' data-aos="fade-left">
                            <div className='background-img'></div>
                            <img src={circle} alt='.../' />
                        </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-6 col-md-8 col-10 d-lg-flex d-none justify-content-lg-start justify-content-end p-4 circle-img-col">
                            <div data-aos="fade-left">
                                <div className="circle">
                                    <img className='w-100 h-100' src={ circle} alt="" />
                                </div>
                            </div>
                        </div> */}
                        <div className='col-6 d-lg-flex justify-content-end d-none p-5'>
                        <div className='big-img' data-aos="fade-left">
                            <div className='background-img'></div>
                            <img src={circle} alt='.../' />
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Process
