import React from 'react'
import { useParams } from 'react-router';
import Banner from '../Component/ServicesDetail/Banner';
import Navbar from '../Component/MainComponents/Navbar';
import SubscribeButton from '../Component/MainComponents/SubscribeButton';
import Footer from '../Component/MainComponents/Footer';
import Data from '../Component/ServicesDetail/Data';
import Process from '../Component/ServicesDetail/Process';
import Work from '../Component/ServicesDetail/Work';
import Projects from '../Component/ServicesDetail/Projects';
import Technologies from '../Component/ServicesDetail/Technologies';
import servicesData from '../Component/AboutContent/data';
import { useAuth } from '../Component/Context/Context';

function ServicesDetail() {
    const { slug } = useParams();
    const service = servicesData.find(service => service.slug.toLocaleLowerCase() === slug);

 const {containerClass} = useAuth()


    return (
        <>
            <Navbar />
            <Banner service={service} />
            <div className={containerClass}><Process service={service} /></div>
            <Work service={service} />
            <Technologies service={service} />
         <div className={containerClass}><Projects service={service} /></div> 
            <Data service={service} />
            <SubscribeButton />
            <Footer />
        </>
    );
}

export default ServicesDetail;
