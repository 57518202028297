import React from 'react'
import { FaArrowRightLong } from 'react-icons/fa6'
import { Link } from 'react-router'

function Button() {
    return (
        <>
            <div className="nask" data-aos="zoom-in">
                <Link to='/contact'>
                    <div className="button">
                        <div className='text'> <i>Get in touch</i>  </div><div className='icon'><FaArrowRightLong /></div>
                    </div>
                </Link>
            </div>
        </>
    )
}

export default Button
