import React, {useEffect, useState } from 'react'
import logo from '../../Images/Nasksoft-logo-1.png'
import { IoMenu, IoPaperPlaneOutline } from 'react-icons/io5'
import { RxCross2 } from 'react-icons/rx'
import { Link } from 'react-router'

function Navbar() {

      const [containerClass, setContainerClass] = useState("container-fluid");
    
        useEffect(() => {
            const updateContainerClass = () => {
                if (window.innerWidth >= 1300) {
                    setContainerClass("container p-0");
                } else {
                    setContainerClass("container-fluid p-0");
                }
            };
    
            updateContainerClass();
            window.addEventListener("resize", updateContainerClass);
        })

    const [active, setActive] = useState(false)

    return (
        <>
            
                <div className="navbar">
                <div className={containerClass}>
                    <div className="row w-100 align-items-center justify-content-between m-0">
                        <div className="col-4 p-0">
                            <div className="logo">
                              <Link to='/'><img src={logo} alt="" /></Link>
                            </div>
                        </div>
                        <div className="col-7 p-0 d-md-inline d-none">
                            <div className="menu">
                              <Link style={{textDecoration:'none'}} to='/'><div className="tab">Home</div></Link>
                              <Link style={{textDecoration:'none'}} to='/about-us'><div className="tab">About Us</div></Link>                          
                                <Link style={{textDecoration:'none'}} to='/services'><div className="tab">Services</div></Link>                              
                                <div className="tab">
                                <Link style={{textDecoration:'none'}} to='/contact'> <button>Contact us <div className="icon"><IoPaperPlaneOutline /></div></button></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-4 d-flex justify-content-end p-0 d-md-none d-inline">
                            <div className="toggle-btn" onClick={() => setActive(!active)}>
                                <IoMenu />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`menu-box ${active ? 'active' : 'inactive'} `} >
                   <div className="d-flex justify-content-end">
                   <div className="close-btn" onClick={()=> setActive(!active)}>
                        <RxCross2/>
                    </div>
                   </div>
                    <div className="h-100 d-flex justify-content-center align-items-center">
                        <div className="new-menu">
                          <Link style={{textDecoration:'none'}} to='/'><div className="tab">Home</div></Link>
                          <Link style={{textDecoration:'none'}} to='/about-us'><div className="tab">About Us</div></Link>                
                            <Link style={{textDecoration:'none'}} to='/services'><div className="tab">Services</div></Link>
                           <Link to='/contact' style={{textDecoration:'none'}}>
                           <div className="tab">
                                <div className='button'>Contact us <div className="icon"><IoPaperPlaneOutline /></div></div>
                            </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Navbar
