import React, { useEffect, useState } from 'react'

function Form() {

 const [containerClass, setContainerClass] = useState("container-fluid");

    useEffect(() => {
        const updateContainerClass = () => {
            if (window.innerWidth >= 1300) {
                setContainerClass("container p-0");
            } else {
                setContainerClass("container-fluid ps-0 pe-lg-5 pe-0");
            }
        };

        updateContainerClass();
        window.addEventListener("resize", updateContainerClass);
    })

    return (
        <>
            <div className={containerClass}>
                <div className="terms">
                    <div className="row m-0">
                        <h1>Terms & Conditions</h1>

                        <p><b>Effective Date: </b>17/02/2025 </p>
                        <p>These Terms & Conditions ("Terms") govern your access to and use of our website located at <a href="https://www.nasksoft.com" target='_blank' style={{color:"black"}}>https://www.nasksoft.com</a> (the "Site"). By visiting or using this Site, you agree to comply with and be legally bound by the Terms outlined below. If you do not agree to these Terms, please refrain from using our website.</p>

                        <h4>1. Acceptance of Terms</h4>
                        <p>By accessing this website, you acknowledge and accept these Terms & Conditions in full, along with our Privacy Policy. These terms constitute a legal agreement between you and Nasksoft. If you are using this website on behalf of a company or organization, you represent that you have the authority to bind such entity to these Terms.</p>

                        <h4>2. Service Overview</h4>
                        <p>Each of the services is presented on our website for informational purposes and reflects our commitment to delivering innovative, high-quality solutions tailored to client needs. Please note that the scope and availability of these services may be updated, modified, or removed at any time without prior notice. Any service engagement between Nasksoft and a client will be governed by a separate agreement that outlines the specific deliverables, terms, and conditions related to that engagement.</p>

                        <h4>3. Intellectual Property Rights</h4>
                        <p>All intellectual property rights on this website, including text, images, graphics, videos, code, branding elements, and other digital assets, are either owned by Nasksoft or used with proper authorization. You may view and download content for personal, non-commercial use only.</p>
                        <p>You may not:</p>
                        <ul>
                            <li>Reproduce or redistribute content from this site without written permission.</li>
                            <li>Use our trademarks, logos, or proprietary materials without consent.</li>
                            <li>Alter or remove any copyright or proprietary notices.</li>
                        </ul>
                        <p>Unauthorized use of any content may violate copyright, trademark, or other laws</p>

                        <h4>4. Use of the Website</h4>
                        <p>You agree to use this website only for lawful purposes and in a manner that does not infringe the rights or restrict the use of this site by others. Prohibited activities include, but are not limited to:</p>
                        <ul>
                            <li>Attempting to access restricted areas without authorization</li>
                            <li>Transmitting malicious software or harmful code</li>
                            <li>Scraping or harvesting data without permission</li>
                            <li>Misrepresenting your identity or impersonating others</li>
                        </ul>
                        <p>We reserve the right to restrict or terminate access to users who violate these terms.</p>

                        <h4>5. Third-Party Links</h4>
                        <p>Our website may include links to third-party websites for your convenience or reference. Nasksoft has no control over the content, privacy policies, or practices of third-party sites and does not endorse or assume responsibility for them. You access third-party websites at your own risk.</p>

                        <h4>6. Limitation of Liability</h4>
                        <p>To the fullest extent permitted by law, Nasksoft will not be liable for any direct, indirect, incidental, consequential, or special damages arising out of or in connection with your access to or use of the website. This includes, but is not limited to, damages for lost profits, data loss, business interruption, or personal injury resulting from:</p>
                        <ul>
                            <li>The use or inability to use the site</li>
                            <li>Unauthorized access to or alteration of your data</li>
                            <li>Any viruses or harmful components transmitted via the site</li>
                        </ul>

                        <h4>7. Service Engagement Terms</h4>
                        <p>Engagements for services such as development, consulting, or support are subject to separate service agreements. These agreements outline detailed terms, deliverables, payment terms, timelines, and responsibilities of both parties. These Terms & Conditions do not override those contracts and are intended only for general website usage.</p>

                        <h4>8. Changes to These Terms</h4>
                        <p>We may update these Terms & Conditions periodically to reflect changes in our services, technology, or legal requirements. Any changes will be posted on this page with an updated effective date. Continued use of the website after such changes constitutes your acceptance of the revised terms.</p>

                        <h4>9. Governing Law</h4>
                        <p>These Terms shall be governed by and construed in accordance with the laws of the Islamic Republic of Pakistan. Any disputes arising out of or in connection with these Terms shall fall under the exclusive jurisdiction of the courts of Pakistan.</p>

                        <h4>10. Contact Information</h4>
                        <p className='mb-4'>If you have any questions, concerns, or feedback regarding these Terms & Conditions, please contact us at:</p>
                        <p><b>Nasksoft</b></p>
                        <p><b>Email:</b> hello@nasksoft.com</p>
                        <p><b>Website:</b><a href="https://www.nasksoft.com" target='_blank' style={{textDecoration:'none'}}> https://www.nasksoft.com</a></p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Form
