import React from "react";
import Waves from "../MainComponents/Waves";
import servicesData from '../AboutContent/data';
import { useParams } from 'react-router';

function Banner() {
    const { slug } = useParams();
    const service = servicesData.find(service => service.slug === slug);

    return (
        <>
            <div className="container-fluid p-0">
                <div className="home_banner services_detail_banner">
                    <div className="row m-0">
                        <div className="col-12 p-0">
                            <div className="banner">
                                <div className="filter-layer">
                                    <div className="row m-0 justify-content-center">
                                        <div className="col-12 p-0">
                                            <div className="text-box">
                                                <div className="heading">
                                                    {service ? service.details[0].page : "Service Not Found"}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Waves />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Banner;
