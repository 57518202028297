import React, { useEffect, useState } from 'react'
import react from '../../Images/react.webp'
import native from '../../Images/android.webp'
import ios from '../../Images/ios.webp'
import angular from '../../Images/angular.webp'
import node from '../../Images/nodejs.webp'
import cloud from '../../Images/devops (1).webp'
import python from '../../Images/python.webp'
import mongo from '../../Images/devicon_mongodb.webp'
import vue from '../../Images/vuejs.webp'

function Technology() {



  const [containerClass, setContainerClass] = useState("container-fluid");

    useEffect(() => {
        const updateContainerClass = () => {
            if (window.innerWidth >= 1300) {
                setContainerClass("container p-0");
            } else {
                setContainerClass("container-fluid ps-0 pe-lg-5 pe-0");
            }
        };

        updateContainerClass();
        window.addEventListener("resize", updateContainerClass);
    })




    return (
        <>
            <div className="container-fluid p-0">
                <div className="technology">
                    <div className="technology-rev">
                        <div className="layer"></div>
                    </div>
                    <div className="box">
                        <div className={containerClass}>
                        <div className="row m-0">
                            <div className="col-12 p-0">
                                <div className="left-side" data-aos="fade-right">
                                    <div className="title">Technology Stack</div>
                                    <div className="heading">Technologies & Tools Stack For Powering Development</div>
                                    <div className="para">Partner with Nasksoft’s IT experts to develop cutting-edge custom software using advanced frameworks and technologies. We deliver innovative, scalable, and efficient solutions tailored to your business needs for optimal growth and success.</div>
                                    <div className="point-box">
                                        <div className="point">
                                            <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.00004 16.4199L0.790039 10.2099L3.62004 7.37988L7.00004 10.7699L16.88 0.879883L19.71 3.70988L7.00004 16.4199Z" fill="#1E96D3"></path></svg>
                                            End-to-End Application Development
                                        </div>
                                        <div className="point">
                                            <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.00004 16.4199L0.790039 10.2099L3.62004 7.37988L7.00004 10.7699L16.88 0.879883L19.71 3.70988L7.00004 16.4199Z" fill="#1E96D3"></path></svg>
                                            Customized Web Solutions
                                        </div>
                                        <div className="point">
                                            <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.00004 16.4199L0.790039 10.2099L3.62004 7.37988L7.00004 10.7699L16.88 0.879883L19.71 3.70988L7.00004 16.4199Z" fill="#1E96D3"></path></svg>
                                            Cross-Platform Mobile Apps
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mt-sm-5 mt-3 py-5 p-0">
                                <div className="right-side d-flex justify-content-center" data-aos="fade-left" style={{ flexWrap: 'wrap', gap: '40px' }}>
                                    <div className="language-card">
                                        <div className="img">
                                            <img src={react} alt="" />
                                        </div>
                                        <div className="text">ReactJs</div>
                                    </div>
                                    <div className="language-card">
                                        <div className="img">
                                            <img src={native} alt="" />
                                        </div>
                                        <div className="text">React Native</div>
                                    </div>
                                    <div className="language-card">
                                        <div className="img">
                                            <img src={ios} alt="" />
                                        </div>
                                        <div className="text">iOS</div>
                                    </div>
                                    <div className="language-card">
                                        <div className="img">
                                            <img src={angular} alt="" />
                                        </div>
                                        <div className="text">Angular</div>
                                    </div>
                                    <div className="language-card">
                                        <div className="img">
                                            <img src={node} alt="" />
                                        </div>
                                        <div className="text">NodeJs</div>
                                    </div>
                                    <div className="language-card">
                                        <div className="img">
                                            <img src={cloud} alt="" />
                                        </div>
                                        <div className="text">Cloud & DevOps</div>
                                    </div>
                                    <div className="language-card">
                                        <div className="img">
                                            <img src={python} alt="" />
                                        </div>
                                        <div className="text">Python</div>
                                    </div>
                                    <div className="language-card">
                                        <div className="img">
                                            <img src={mongo} alt="" />
                                        </div>
                                        <div className="text">MongoDB</div>
                                    </div>
                                    <div className="language-card">
                                        <div className="img">
                                            <img src={vue} alt="" />
                                        </div>
                                        <div className="text">Vue.js</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Technology
