import React, { useState } from 'react'
import img from '../../Images/bg-square.webp'
import img1 from '../../Images/contactus.webp'
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaPinterestP, FaTiktok, FaYoutube } from 'react-icons/fa'
import emailjs from '@emailjs/browser';

function Form() {


    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
        isCheckboxSelected: false, // Tracks checkbox state
        isSelectDisabled: false, // Tracks select state
    });

    const [errors, setErrors] = useState({});
    const [isSending, setIsSending] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleCheckboxChange = (e) => {
        const isChecked = e.target.checked;
        setFormData({
            subject: isChecked ? "General Inquiry" : "", // Store value only if checked
            isCheckboxSelected: isChecked,
            isSelectDisabled: isChecked, // Disable select when checkbox is checked
        });
    };

    const handleSelectChange = (e) => {
        const value = e.target.value;
        setFormData({
            subject: value,
            isCheckboxSelected: false, // Uncheck checkbox when select is used
            isSelectDisabled: value !== "", // Disable select box only if an option is chosen
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setSuccessMessage(""); // Clear previous success message
        setIsSending(true); // Show loading state

        let newErrors = {};

        // Validation Rules
        if (!formData.firstName || formData.firstName.trim() === "") {
            newErrors.firstName = "First name is required.";
        }
        if (!formData.lastName || formData.lastName.trim() === "") {
            newErrors.lastName = "Last name is required.";
        }
        
        if (!formData.email || formData.email.trim() === "") {
            newErrors.email = "Email is required.";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = "Invalid email format.";
        }
        
        if (!formData.phone || formData.phone.trim() === "") {
            newErrors.phone = "Phone number is required.";
        } else if (!/^(?:\+92\d{10}|03\d{9})$/.test(formData.phone)) {
            newErrors.phone = "Invalid Pakistani phone number format. Use +92XXXXXXXXXX or 03XXXXXXXXX.";
        }
        
        
        if (!formData.subject) {
            newErrors.subject = "Please select a subject.";
        }
        
        if (!formData.message || formData.message.trim() === "") {
            newErrors.message = "Message cannot be empty.";
        }
        
        

        setErrors(newErrors);

        // If no errors, send email
        if (Object.keys(newErrors).length === 0) {
            const serviceID = "service_n0xonde"; // Replace with your Email.js Service ID
            const templateID = "template_pzitugc"; // Replace with your Email.js Template ID
            const publicKey = "5fCC3gfcqOKw8hd9k"; // Replace with your Email.js Public Key

            const templateParams = {
                firstName: formData.firstName,
                lastName: formData.lastName,
                email: formData.email,
                phone: formData.phone,
                subject: formData.subject,
                message: formData.message,
            };

            emailjs.send(serviceID, templateID, templateParams, publicKey)
                .then((response) => {
                    console.log("Email sent successfully!", response);
                    setSuccessMessage("Your message has been sent successfully!");
                    setFormData({
                        firstName: "",
                        lastName: "",
                        email: "",
                        phone: "",
                        subject: "",
                        message: "",
                        isCheckboxSelected: false, // Reset checkbox state
                        isSelectDisabled: false, // Reset select state
                    });
                })
                .catch((error) => {
                    console.error("Email sending failed!", error);
                })
                .finally(() => {
                    setIsSending(false);
                });
        } else {
            setIsSending(false);
        }
    };

    return (
        <>
            <div className="container-fluid p-0">
                <div className="row m-0 justify-content-center">
                    <div className="col-12 p-0">
                        <div className="form p-2" data-aos="zoom-in">
                            <div className="row m-0">
                                <div className="col-md-6 col-12 p-0">
                                    <div className="left-side">
                                        <img className='img1' src={img} alt="" />
                                        <img className='img2' src={img1} alt="" />
                                        <div className="row m-0 form-row" style={{ alignContent: 'space-between', height: '540px' }}>
                                            <div className="col-12 p-0">
                                                <div className="head">Contact Information</div>
                                                <div className="para">Say something to start a live chat!</div>
                                            </div>
                                            <div className="col-12 form-col p-0" style={{ display: 'grid', gap: '50px' }}>
                                                <div className="detail">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.0002 10.999H22.0002C22.0002 5.869 18.1272 2 12.9902 2V4C17.0522 4 20.0002 6.943 20.0002 10.999Z" fill="white"></path><path d="M12.9998 7.99999C15.1028 7.99999 15.9998 8.89699 15.9998 11H17.9998C17.9998 7.77499 16.2248 5.99999 12.9998 5.99999V7.99999ZM16.4218 13.443C16.2296 13.2683 15.9771 13.1752 15.7176 13.1832C15.4581 13.1912 15.2118 13.2998 15.0308 13.486L12.6378 15.947C12.0618 15.837 10.9038 15.476 9.71179 14.287C8.51979 13.094 8.15879 11.933 8.05179 11.361L10.5108 8.96699C10.6972 8.78612 10.8059 8.53982 10.814 8.2802C10.822 8.02059 10.7287 7.76804 10.5538 7.57599L6.85879 3.51299C6.68384 3.32035 6.44067 3.2035 6.18095 3.18725C5.92122 3.17101 5.66539 3.25665 5.46779 3.42599L3.29779 5.28699C3.1249 5.46051 3.02171 5.69145 3.00779 5.93599C2.99279 6.18599 2.70679 12.108 7.29879 16.702C11.3048 20.707 16.3228 21 17.7048 21C17.9068 21 18.0308 20.994 18.0638 20.992C18.3083 20.9783 18.5391 20.8747 18.7118 20.701L20.5718 18.53C20.7413 18.3325 20.8271 18.0768 20.811 17.817C20.795 17.5573 20.6783 17.3141 20.4858 17.139L16.4218 13.443Z" fill="white"></path></svg>
                                                    +92305-1115551
                                                </div>
                                                <div className="detail">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22 4H2V20H22V4ZM20 8L12 13L4 8V6L12 11L20 6V8Z" fill="white"></path></svg>
                                                    hello@nasksoft.com
                                                </div>
                                                <div className="detail pe-5">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 1.5C9.81276 1.50258 7.71584 2.3726 6.16923 3.91922C4.62261 5.46584 3.75259 7.56276 3.75001 9.75C3.74739 11.5374 4.33124 13.2763 5.41201 14.7C5.41201 14.7 5.63701 14.9963 5.67376 15.039L12 22.5L18.3293 15.0353C18.3623 14.9955 18.588 14.7 18.588 14.7L18.5888 14.6978C19.669 13.2747 20.2526 11.5366 20.25 9.75C20.2474 7.56276 19.3774 5.46584 17.8308 3.91922C16.2842 2.3726 14.1873 1.50258 12 1.5ZM12 12.75C11.4067 12.75 10.8266 12.5741 10.3333 12.2444C9.83995 11.9148 9.45543 11.4462 9.22837 10.8981C9.00131 10.3499 8.9419 9.74667 9.05765 9.16473C9.17341 8.58279 9.45913 8.04824 9.87869 7.62868C10.2982 7.20912 10.8328 6.9234 11.4147 6.80764C11.9967 6.69189 12.5999 6.7513 13.1481 6.97836C13.6962 7.20542 14.1648 7.58994 14.4944 8.08329C14.8241 8.57664 15 9.15666 15 9.75C14.999 10.5453 14.6826 11.3078 14.1202 11.8702C13.5578 12.4326 12.7954 12.749 12 12.75Z" fill="white"></path></svg>
                                                    Building No. 21, 3rd Floor, Block-CCA, DHA Phase 8 - Ex Park
                                                    View Phase-8 Lahore, 54000.
                                                </div>
                                            </div>
                                            <div className="col-12 mt-md-0 mt-2 p-0">
                                                <div className="social-box">
                                                    <a href="https://www.facebook.com/nasksoft/">
                                                        <div className="social-icon">
                                                            <FaFacebookF />
                                                        </div>
                                                    </a>
                                                    <a href="https://www.instagram.com/nasksoft/">
                                                        <div className="social-icon">
                                                            <FaInstagram />
                                                        </div>
                                                    </a>
                                                    <a href="https://www.linkedin.com/company/nasksoft/" target='_blank'><div className="social-icon"><FaLinkedinIn /></div></a>
                                                    {/* <a href="" target='_blank'>  <div className="social-icon"><PiTelegramLogoThin /></div></a>  */}
                                                    <a href="https://www.youtube.com/@nasksoft" target='_blank'><div className="social-icon"><FaYoutube /></div></a>
                                                    <a href="https://www.tiktok.com/@nasksoft" target='_blank'><div className="social-icon"><FaTiktok /></div></a>
                                                    <a href="https://www.pinterest.com/nasksoft_/" target='_blank'><div className="social-icon"><FaPinterestP /></div></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <form className="col-md-6 mt-md-0 mt-3 col-12 p-0">
                                    <div className="right-side">
                                        <div className="row m-0" style={{ gap: "30px 0" }}>
                                            <div className="col-sm-6 col-12 p-0">
                                                <div className="input-box">
                                                    <label>First Name</label>
                                                    <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} required />
                                                    {<p className="error">{errors.firstName}</p>}
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-12 p-0">
                                                <div className="input-box">
                                                    <label>Last Name</label>
                                                    <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} required />
                                                    {<p className="error">{errors.lastName}</p>}
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-12 p-0">
                                                <div className="input-box">
                                                    <label>Email</label>
                                                    <input type="email" name="email" value={formData.email} onChange={handleChange} required />
                                                    {<p className="error">{errors.email}</p>}
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-12 p-0">
                                                <div className="input-box">
                                                    <label>Phone Number</label>
                                                    <input type="number" name="phone" value={formData.phone} onChange={handleChange} required />
                                                    {<p className="error">{errors.phone}</p>}
                                                </div>
                                            </div>
                                            <div className="col-12 p-0">
                                                <div className="input-box new_input_box">
                                                    <label>Select Subject</label>
                                                    <div className="d-flex radio-parent mt-2 pt-1" style={{ gap: "40px" }}>
                                                        {/* Checkbox Option */}
                                                        <div className="checkbox d-flex align-items-center" style={{ gap: '5px' }}>
                                                            <input
                                                                type="checkbox"
                                                                id="general-inquiry"
                                                                checked={formData.isCheckboxSelected}
                                                                onChange={handleCheckboxChange}
                                                                disabled={formData.subject && !formData.isCheckboxSelected} // Disable if select is used
                                                            />
                                                            <label htmlFor="general-inquiry">General Inquiry</label>
                                                        </div>

                                                        {/* Select Box Option */}
                                                        <select
                                                            className="form-select" aria-label="Default select example"
                                                            value={formData.subject}
                                                            onChange={handleSelectChange}
                                                            disabled={formData.isCheckboxSelected} // Disable select if checkbox is checked
                                                        >
                                                            <option value="">Select a service</option>
                                                            <option value="Digital Marketing">Digital Marketing</option>
                                                            <option value="Web Development">Web Development</option>
                                                            <option value="Graphic Design Services">Graphic Design Services</option>
                                                            <option value="Mobile App Development">Mobile App Development</option>
                                                            <option value="IT Consultancy">IT Consultancy</option>
                                                            <option value="DevOps">DevOps</option>
                                                            <option value="CRMs">CRMs</option>
                                                            <option value="Content Management">Content Management</option>
                                                            <option value="Digital Transformation">Digital Transformation</option>
                                                            <option value="Ecommerce Solutions">Ecommerce Solutions</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 p-0">
                                                <div className="input-box">
                                                    <label>Message</label>
                                                    <textarea name="message" placeholder="Write your message..." value={formData.message} required onChange={handleChange}></textarea>
                                                    {errors.message && <p className="error">{errors.message}</p>}
                                                </div>
                                            </div>
                                            <div className="col-12 d-flex justify-content-sm-end justify-content-center">
                                                <div className='button' onClick={handleSubmit} type="submit">{isSending ? "Sending..." : "Send Message"}</div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Form
