import React, { useState } from "react";
import emailjs from "@emailjs/browser";

function SubscribeButton() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [buttonText, setButtonText] = useState("Subscribe"); // Button text state
  const [isDisabled, setIsDisabled] = useState(false); // Disable button during request

  const handleChange = (e) => {
    setEmail(e.target.value);
    setError("");
    setSuccess("");
    setButtonText("Subscribe"); // Reset button text when user types
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email.trim()) {
      setError("Email is required.");
      return;
    }

    if (!emailRegex.test(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    setButtonText("Subscribing..."); // Show loading state
    setIsDisabled(true); // Disable button while sending

    const serviceID = "service_n0xonde"; 
    const templateID = "template_g4uwi0r";
    const publicKey = "5fCC3gfcqOKw8hd9k";

    const templateParams = {
      email: email,
    };

    emailjs.send(serviceID, templateID, templateParams, publicKey)
      .then((response) => {
        console.log("Email sent successfully!", response);
        setSuccess("Subscribed successfully!");
        setButtonText("Subscribed!"); // Show success
        setEmail(""); 
        setTimeout(() => {
          setButtonText("Subscribe"); // Reset after a few seconds
          setIsDisabled(false);
        }, 3000);
      })
      .catch((err) => {
        console.error("Failed to send email", err);
        setError("Failed to subscribe. Please try again.");
        setButtonText("Failed!"); // Show error
        setTimeout(() => {
          setButtonText("Subscribe");
          setIsDisabled(false);
        }, 3000);
      });
  };

  return (
    <div className="container-fluid p-0">
      <div className="subscribe-box p-sm-4 py-4 px-2">
        <div className="container">
          <div className="row justify-content-center align-items-center m-0">
            <div className="col-lg-10 col-sm-11 col-12 p-0">
              <form onSubmit={handleSubmit}>
                <div className="field-box p-1" style={{ boxShadow: "8px 8px 12px rgba(0, 0, 0, 0.2)" }}>
                  <input
                    required
                    type="email"
                    placeholder="Subscribe to our newsletter"
                    value={email}
                    onChange={handleChange}
                  />
                  <button type="submit" className="button" disabled={isDisabled}>
                    {buttonText}
                  </button>
                </div>
                {/* {error && <p className="error">{error}</p>}
                {success && <p className="success">{success}</p>} */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscribeButton;
