import React from "react";
import Waves from "../MainComponents/Waves";

function Banner() {

    return (
        <>
            <div className="container-fluid p-0">
                <div className="home_banner contact_banner">
                    <div className="row m-0">
                        <div className="col-12 p-0">
                            <div className="banner">
                                <div className="filter-layer">
                                    <div className="row m-0 justify-content-center">
                                        <div className=" col-12 p-0">
                                            <div className="text-box">
                                                <div className="heading">Contact Us</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <Waves />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Banner;
