import React from 'react'
import Navbar from '../Component/MainComponents/Navbar'
import Form from '../Component/PrivacyPolicy/Form'
import Footer from '../Component/MainComponents/Footer'

function PrivacyPolicy() {
  return (
    <>
     <Navbar/>
     <Form/>
     <Footer/> 
    </>
  )
}

export default PrivacyPolicy
