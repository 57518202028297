import React from 'react'
import Banner from '../Component/ContactUs/banner'
import Navbar from '../Component/MainComponents/Navbar'
import Form from '../Component/ContactUs/Form'
import SubscribeButton from '../Component/MainComponents/SubscribeButton'
import Footer from '../Component/MainComponents/Footer'
import { useAuth } from '../Component/Context/Context'
import Map from '../Component/MainComponents/Map'
function ContactUs() {

  const {containerClass} = useAuth()

  return (
    <>
     <Navbar /> 
     <Banner />
     <div className={containerClass}><Form/>
     <Map />
     </div>
     <SubscribeButton/>
     <Footer/>
    </>
  )
}

export default ContactUs
