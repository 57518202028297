import React, { useEffect, useState } from "react";
import Waves from "../MainComponents/Waves";

function Banner() {



    const [containerClass, setContainerClass] = useState("container-fluid");

    useEffect(() => {
        const updateContainerClass = () => {
            if (window.innerWidth >= 1300) {
                setContainerClass("container p-0");
            } else {
                setContainerClass("container-fluid ps-0 pe-lg-5 pe-0");
            }
        };

        updateContainerClass();
        window.addEventListener("resize", updateContainerClass);
    })



    const [counts, setCounts] = useState([300, 0, 0, 0]);

    useEffect(() => {

        const targetValues = [500, 40, 40, 5];
        const duration = 2000;
        const interval = 20;

        const steps = targetValues.map((target) => Math.ceil(target / (duration / interval)));

        const intervalIds = targetValues.map((target, index) =>
            setInterval(() => {
                setCounts((prevCounts) => {
                    const newCounts = [...prevCounts];
                    if (newCounts[index] < target) {
                        newCounts[index] += steps[index];
                    } else {
                        clearInterval(intervalIds[index]);
                        newCounts[index] = target;
                    }
                    return newCounts;
                });
            }, interval)
        );

        return () => intervalIds.forEach(clearInterval);
    }, []);
    const labels = ["Projects", "Our Client", "Happy Client", "Experience"];

    return (
        <div className="container-fluid p-0">
            <div className="home_banner new_home_banner">
                <div className="home-rev border">
                    <div className={containerClass}>
                        <div className="filter-layer"></div>
                        <div className="banner"></div>
                        <div className="row justify-content-center">
                            <div className="col-lg-10 col-12 p-0">
                                <div className="number-box d-flex justify-content-between align-items-center" data-aos="fade-up">
                                    {counts.map((count, index) => (
                                        <React.Fragment key={index}>
                                            <div className="box text-center">
                                                <div className="heading">
                                                    {count}
                                                    {index === 3 ? "yr+" : "+"}
                                                </div>
                                                <div className="para">{labels[index]}</div>
                                            </div>
                                            {index < counts.length - 1 && <div className="line"></div>}
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className="row w-100 m-0">
                            <div className="col-12 p-0">
                                <div className="row m-0 justify-content-center">
                                    <div className="col-lg-11 col-12 p-0">
                                        <div className="text-box">
                                            <div className="heading">Tech-Powered Solutions To Impact the World</div>
                                            <div className="para">
                                                We provide cutting-edge digital design, development, and marketing solutions, certifying innovative businesses with innovative strategies, and impactful digital experiences for growth and success.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
                        <Waves />
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Banner;
