import React from 'react'
import servicesData from '../AboutContent/data';
import { useParams } from 'react-router';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

function Projects() {

    const { slug } = useParams();
    const service = servicesData.find(service => service.slug === slug);

    return (
        <>
            <div className="container-fluid p-0">
                <div className="services-project">
                    <div className="row">
                        <div className="col-lg-9 col-12  p-lg-0 ps-0 pe-sm-5 pe-0">
                            <div className="heading">Our Project</div>
                            <div className="para">Explore our diverse range of projects, each representing our expertise, creativity, and commitment to excellence. From initial concept to final execution, these projects highlight our dedication to delivering innovative, high-quality, and impactful solutions across various industries. Whether small-scale or large-scale, each project is a testament to our strategic approach, problem-solving capabilities, and passion for achieving outstanding results.</div>
                        </div>
                        <div className="d-flex justify-content-between carousel-parent p-0 w-100">
                            <Swiper
                                slidesPerView={3}
                                spaceBetween={16}
                                loop={true}
                                breakpoints={{
                                    568: { slidesPerView: 2 },
                                    768: { slidesPerView: 3 },
                                    1024: { slidesPerView: 4 },
                                }}>
                                {service?.details[0].projectCard.map((data, index) => (
                                    <SwiperSlide style={{ paddingBottom: '70px' }} key={index}>
                                        <div className="project-card-box mt-lg-0 mt-5">
                                            <div className="project-card" data-aos="zoom-in">
                                               <div>
                                               <div className="img mb-3" style={{height:'160px'}}>
                                                    <img src={data.img} alt="" />
                                                </div>
                                                <div className="title mb-2">{data.name}</div>
                                                <div className="text ps-1">{data.description}</div>
                                               </div>
                                                <div className="button-box d-flex">
                                                   <a href={data.a} target='_blank' style={{textDecoration:"none"}}> <div className="first-button">View Live</div></a>
                                                    {/* <div className="second-button">Github Repo</div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Projects
