import React from 'react'
import Navbar from '../Component/MainComponents/Navbar'
import Form from '../Component/TermsAndCondition/Form'
import Footer from '../Component/MainComponents/Footer'

function TermsCondition() {
  return (
    <>
     <Navbar/>
     <Form/>
     <Footer/> 
    </>
  )
}

export default TermsCondition
