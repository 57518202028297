import React, { useState } from 'react'
import faq from '../../Images/faq.dd203c42.svg'
import faq1 from '../../Images/2359427.jpg'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'

function FAQ() {

    const [openIndex, setOpenIndex] = useState(null);
    const toggleOpen = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const faqs = [
        { id: 1, question: "Who needs digital transformation and automation?", answer: "Businesses aiming for efficiency and scalability." },
        { id: 2, question: "Who can benefit from our IT and tech solutions?", answer: "Companies seeking innovation and growth." },
        { id: 3, question: "Who can improve with digital marketing?", answer: "Brands looking to boost online presence." }
    ];

    return (
        <div className="container-fluid p-0">
            <div className="faq">
                <div className="row justify-content-lg-start justify-content-center m-0">
                    <div className="col-lg-5 col-sm-6 col-8 p-0 mt-lg-0 mt-4">
                        <div className="faq"  data-aos="fade-right">
                            <img className="w-100 h-100 d-lg-inline d-none" src={faq} alt="FAQ Illustration" />
                            <img className="w-100 h-100 d-lg-none d-inline" src={faq1} alt="FAQ Illustration" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 p-0 d-flex align-items-center">
                        <div className="faq-parent w-100 ps-sm-4 px-2" data-aos="fade-left" style={{ gap: '25px', display: 'grid' }}>
                            {faqs.map((item, index) => (
                                <div className="faq-box" key={item.id}>
                                    <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }} onClick={() => toggleOpen(index)}>
                                        <div className="first-box d-flex">
                                            <div className="number">{item.id}</div>
                                            <div className="question">{item.question}</div>
                                        </div>
                                        <div className="arrow">
                                            {openIndex === index ? <IoIosArrowUp /> : <IoIosArrowDown />}
                                        </div>
                                    </div>
                                    <div className={`answer ${openIndex === index ? "open" : "answer"}`}>
                                        {item.answer}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FAQ;
