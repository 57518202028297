import React from 'react' 
import img1 from '../../Images/project/studio Hub.png'
import img2 from '../../Images/project/Screenshot 2025-03-26 174859.png'
import img3 from '../../Images/project/pakiatan property.png'
import { MdOutlineArrowOutward } from 'react-icons/md'
function Portfolio() {
    return (
        <>
            <div className='container-fluid p-lg-5 p-md-4 p-3  mt-5'>
                <div className='portfolio-heading d-md-flex d-block align-items-center pb-lg-0 pb-sm-3 p-0'>
                   <div>
                   <h4>Check Out Our Latest Work
                   </h4>
                   <h2 className='mb-5'>Don’t take our word for it, let our work do the talking.</h2>
                   </div>
                   <div className='button'> 
                  <div className="d-flex justify-content-end">
                  {/* <button>
                    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.6665 0.166992C7.83353 0.166992 0.666504 7.33402 0.666504 16.167C0.666504 25 7.83353 32.167 16.6665 32.167C25.4821 32.167 32.6665 25 32.6665 16.167C32.6665 7.33402 25.4821 0.166992 16.6665 0.166992ZM27.2348 7.54226C29.1437 9.86764 30.2891 12.8351 30.3238 16.0455C29.8726 15.9588 25.3606 15.039 20.814 15.6117C20.7099 15.3861 20.6231 15.1431 20.519 14.9002C20.2413 14.2408 19.929 13.564 19.6166 12.9219C24.6491 10.8742 26.9398 7.92404 27.2348 7.54226ZM16.6665 2.52708C20.1372 2.52708 23.3129 3.8286 25.7251 5.96309C25.4821 6.31016 23.417 9.06938 18.558 10.8915C16.3194 6.7787 13.8379 3.41211 13.4561 2.8915C14.4799 2.64855 15.5559 2.52708 16.6665 2.52708ZM10.8531 3.81124C11.2175 4.29714 13.647 7.68109 15.9203 11.7071C9.53418 13.4078 3.89427 13.3731 3.28689 13.3731C4.17192 9.13879 7.03526 5.61601 10.8531 3.81124ZM2.99188 16.1844C2.99188 16.0455 2.99188 15.9067 2.99188 15.7679C3.5819 15.7852 10.211 15.872 17.0309 13.8243C17.4301 14.5878 17.7945 15.3687 18.1416 16.1496C17.968 16.2017 17.7771 16.2538 17.6036 16.3058C10.558 18.5791 6.80967 24.7917 6.4973 25.3123C4.3281 22.9002 2.99188 19.6898 2.99188 16.1844ZM16.6665 29.8416C13.5081 29.8416 10.5928 28.7657 8.28472 26.9609C8.52767 26.4577 11.3042 21.1128 19.0092 18.423C19.0439 18.4056 19.0613 18.4056 19.096 18.3883C21.0222 23.3687 21.8032 27.5509 22.0114 28.7483C20.3628 29.4598 18.558 29.8416 16.6665 29.8416ZM24.2847 27.4989C24.1459 26.6659 23.417 22.6746 21.6296 17.7635C25.916 17.0867 29.6643 18.1974 30.1329 18.3536C29.5429 22.154 27.3563 25.4338 24.2847 27.4989Z" fill="white"></path></svg>
                    Visit My Dribbble
                    </button> */}
                  </div>
                   </div>
                </div>
                <div className='row d-flex justify-content-md-between justify-content-center px-lg-0 px-sm-5 px-3'>
                    <div className='col-lg-4 col-md-6 col-12 mb-md-5 mb-4 pb-md-0 pb-2'>
                        <div className='portfolio-card' data-aos="zoom-in">
                            <div className='img-portfolio'>
                                <img src={img1}alt='.../'/>
                            </div>
                            <div className='port-heading'>
                            <h2>Photography Studio Hub
                            </h2>
                            <p>Nasksoft offers professional photography and studio services to enhance brand identity and product representation. From e-commerce to portfolio shoots, the expert team delivers high-quality visuals that captivate audiences and drive conversions. Nasksoft ensures top-tier platform.</p>
                            <a href="https://photographystudiohub.com/" style={{textDecoration:'none'}} target='_blank'><div className="button">View Live</div></a>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6 col-12 mb-md-5 mb-4 pb-md-0 pb-2'>
                        <div className='portfolio-card' data-aos="zoom-in">
                            <div className='img-portfolio'>
                                <img src={img2}alt='.../'/>
                            </div>
                            <div className='port-heading'>
                            <h2>Wholesale Shopping
                            </h2>
                            <p>Nasksoft developed Wholesale Shopping, an innovative platform that simplifies bulk purchasing for retailers. Offering digital marketing, branding, sales optimization, and e-commerce strategies, Nasksoft helps retailers scale, optimize operations, and enhance customer satisfaction.
                            </p>
                            <a href="https://www.wholesaleshopping.co.uk/" style={{textDecoration:'none'}} target='_blank'><div className="button">View Live</div></a>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6 col-12 mb-md-5 mb-4 pb-md-0 pb-2'>
                        <div className='portfolio-card' data-aos="zoom-in">
                            <div className='img-portfolio'>
                                <img src={img3}alt='.../'/>
                            </div>
                            <div className='port-heading'>
                            <h2>Pakistan Property
                            </h2>
                            <p>Nasksoft developed Pakistan Property, a platform simplifying property searches and real estate transactions. With custom web development and IT services, it offers refined search filters, secure integrations, and user-friendly listings, enhancing efficiency for buyers and sellers.
                            </p>
                           <a href="https://www.pakistanproperty.com/" style={{textDecoration:'none'}} target='_blank'><div className="button">View Live</div></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Portfolio
