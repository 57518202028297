import { BrowserRouter, Routes, Route } from 'react-router';
import './App.css';
import Homepage from './Pages/Homepage';
import ContactUs from './Pages/ContactUs';
import Services from './Pages/Services';
import ServicesDetail from './Pages/ServicesDetail';
import AOS from "aos";
import "aos/dist/aos.css";
import Aboutus from './Pages/Aboutus';
import TermsCondition from './Pages/TermsCondition';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import ScrollToTop from './Pages/ScrollToTop';

AOS.init({
  duration: 800,
  once: false,
});

function App() {
  return (
    <BrowserRouter>
      <div style={{overflowX:"hidden"}}>
        <ScrollToTop/>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/about-us" element={<Aboutus />} />
          <Route path="/services" element={<Services />} />
          <Route path="/services/:slug" element={<ServicesDetail />} />
          <Route path="/Terms&Condition" element={<TermsCondition />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy/>} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
