import React from 'react'

function Standered() {
  return (
   <>
   <div className='standered mt-5'> 
    <h3>Our Standards</h3>
    <h2>Core Values of Our Commitment<br/> to Excellence
    </h2>
   </div>
   </>
  )
}

export default Standered
