import React, { useEffect, useState } from 'react'
import video from '../../Images/bgVideo.mp4'
import { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-coverflow";

function TechMessages() {

    const [containerClass, setContainerClass] = useState("container-fluid");

    useEffect(() => {
        const updateContainerClass = () => {
            if (window.innerWidth >= 1300) {
                setContainerClass("container p-0");
            } else {
                setContainerClass("container-fluid ps-0 pe-lg-5 pe-0");
            }
        };

        updateContainerClass();
        window.addEventListener("resize", updateContainerClass);
    })


    const data = [
        { id: 1, title: "Cutting-Edge Solutions", description: 'We deliver advanced, scalable, and secure technology to keep businesses ahead in the digital era.' },
        { id: 2, title: "Tailored Digital Experiences", description: 'Our customized solutions align with industry needs, ensuring seamless integration and user experience.' },
        { id: 3, title: "Reliability & Excellence", description: 'We focus on precision, and long-term success with high-quality, dependable technology solutions.' },
    ];

    const swiperRef = useRef(null)

    return (
        <>
            <div className="container-fluid p-0">
                <div className="tech">
                    <div className="tech-rev">
                    <div className="layer"></div>
                    <video src={video} autoPlay loop muted ></video>
                    <div className={containerClass}>
                    <div className="row m-0">
                        <div className="col-lg-6 col-12 p-0">
                            <div className="text-box px-lg-5 ps-3 pe-5 pt-2 pb-4 mx-3" data-aos="fade-right">
                                <div>
                                    <div className="heading">Technologies</div>
                                    <div className="para">Our deep expertise and strategic planning let us deliver customized, cutting-edge technology that is up to the mark of today’s digital-first world and lasting success.</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-lg-0 mt-5 pt-lg-0 pt-4 p-0">                         
                                <div className="slide-parent" data-aos="fade-left">
                                    <div className='second-parent'>
                                        <div className="slide-box">
                                            <div className="slide1"></div>
                                            <div className="slide2"></div>
                                            <div className="slide3"></div>
                                            <div className="slide4"></div>
                                            <div className="slide5"></div>
                                            <div className="slider-card">
                                                <div className="relative w-full h-100">
                                                    <Swiper
                                                        modules={[EffectCoverflow]}
                                                        effect="coverflow"
                                                        grabCursor={true}
                                                        centeredSlides={true}
                                                        slidesPerView={1}
                                                        coverflowEffect={{
                                                            rotate: 0,
                                                            stretch: 0,
                                                            depth: 200,
                                                            modifier: 2,
                                                            slideShadows: false,
                                                        }}
                                                        loop={true}
                                                        className="relative z-[99]"
                                                        onSwiper={(swiper) => (swiperRef.current = swiper)}
                                                    >
                                                        {data.map((text, index) => (
                                                            <SwiperSlide key={index}>
                                                                <div
                                                                    style={{ borderRadius: "16px", padding: "1.5rem" }}
                                                                    className="transition-all duration-500 ease-in-out transform bg-white p-6 md:p-10 shadow-lg rounded-2xl border border-gray-200 scale-90 opacity-80 swiper-slide-active:scale-100 swiper-slide-active:opacity-100 h-100"
                                                                >
                                                                    <h3 className="text-2xl font-semibold text-gray-900 slide-card-text">
                                                                        {text.title}
                                                                    </h3>
                                                                    <p className="text-gray-500 mt-2 slide-card-para">
                                                                        {text.description}
                                                                    </p>
                                                                    <div className="d-flex justify-content-end mt-4 w-100 tech_message_arrow">
                                                                        <svg
                                                                            className="ml-auto cursor-pointer"
                                                                            width="41"
                                                                            height="13"
                                                                            viewBox="0 0 41 13"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            onClick={() => swiperRef.current?.slideNext()}
                                                                        >
                                                                            <path
                                                                                d="M0.943579 6.64421C0.951029 7.12084 1.14728 7.57505 1.48925 7.90714C1.83123 8.23923 2.29099 8.42208 2.76763 8.41555L33.3583 8.41555V12.0742C33.3583 12.8498 34.2945 13.2381 34.8415 12.6893L40.2996 7.23115C40.6397 6.89105 40.6397 6.34115 40.2996 6.00105L34.8415 0.542942C34.2945 -0.0058918 33.3583 0.384224 33.3583 1.15799V4.81664L2.76763 4.81664C2.52689 4.81335 2.28792 4.85839 2.0649 4.94911C1.84188 5.03984 1.63932 5.17439 1.46924 5.3448C1.29915 5.51521 1.16499 5.71803 1.0747 5.94123C0.984405 6.16442 0.939819 6.40347 0.943579 6.64421Z"
                                                                                fill="black"
                                                                            ></path>
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            </SwiperSlide>
                                                        ))}
                                                    </Swiper>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TechMessages
