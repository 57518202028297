import React from 'react'

function Map() {
  return (
    <>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13604.362497224101!2d74.42700851609969!3d31.521670828933576!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39190f0edb1ad977%3A0xf6b165821d6a6dc!2sDHA%20Phase%208%20-%20Ex%20Park%20View%20Park%20View%20CHS%2C%20Lahore%2C%20Punjab%2C%20Pakistan!5e0!3m2!1sen!2s!4v1722356405588!5m2!1sen!2s" width="100%" height="460px" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='location'></iframe>
    </>
  )
}

export default Map
