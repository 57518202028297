import React from "react";

function Waves() {
  return (
    <div className="wave-container">
      {/* First Wave */}
      <svg viewBox="0 0 1440 320" xmlns="http://www.w3.org/2000/svg" className="wave wave1">
        <path
          fill="#ffffff"
          fillOpacity="0.7"
          d="M0,160L80,170.7C160,181,320,203,480,202.7C640,203,800,181,960,176C1120,171,1280,181,1360,186.7L1440,192V320H0Z"
        ></path>
      </svg>

      {/* Second Wave */}
      <svg viewBox="0 0 1440 320" xmlns="http://www.w3.org/2000/svg" className="wave wave2">
        <path
          fill="#ffffff"
          fillOpacity="0.5"
          d="M0,192L80,181.3C160,171,320,149,480,149.3C640,149,800,171,960,181.3C1120,192,1280,192,1360,192L1440,192V320H0Z"
        ></path>
      </svg>

      {/* Third Wave */}
      <svg viewBox="0 0 1440 320" xmlns="http://www.w3.org/2000/svg" className="wave wave3">
        <path
          fill="#ffffff"
          fillOpacity="0.3"
          d="M0,224L100,213.3C200,203,400,181,600,192C800,203,1000,245,1200,250.7C1400,256,1600,224,1700,208L1800,192V320H0Z"
        ></path>
      </svg>
    </div>
  );
}

export default Waves;
