import React from 'react'
import video from '../../Images/globe.mp4'

function Statisfaction() {
  return (
    <>
     <div className="container-fluid p-0">
        <div className="section">
        <div className="video"><video src={video} autoPlay loop muted ></video></div>
            <div className="row m-0 w-100 h-100 align-items-lg-center justify-content-end">
               <div className="col-lg-7 col-12 ps-lg-5 pe-lg-4 pe-sm-5 px-0" data-aos="zoom-in">
                <div className="title">Why Choose Us</div>
                <div className="heading">We Prioritize Success Through Customer-Centric Innovation</div>
                <div className="para">We focused on empowering businesses through custom software solutions, leveraging advanced technology and strategic digital transformation to drive growth, efficiency, and long-term success.</div>
               </div>
            </div>
        </div>
        </div> 
    </>
  )
}

export default Statisfaction
